<template>
  <div>
    <div class="logo-and-name">
      <img
        src="../../public/logo.png"
        alt=""
        style="
          width: 100px;
          height: 100px;
          border-radius: 50%;
          vertical-align: middle;
        "
      />
      <span style="font-weight: 700; font-size: 32px; margin-left: 20px"
        >天津职健网管理平台</span
      >
      <div style="color: #c5c5c5; font-size: 14px; margin-top: 10px">
        让你的学习更加系统~
      </div>
    </div>
    <div class="register-container">
      <el-form
        ref="registerForm"
        :model="registerForm"
        :rules="registerRules"
        class="register-form"
      >
        <!-- 用户名 -->
        <el-form-item prop="userName">
          <el-input
            prefix-icon="el-icon-user"
            v-model="registerForm.userName"
            placeholder="请输入用户名"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 手机号 -->
        <el-form-item prop="phone">
          <el-input
            prefix-icon="el-icon-phone"
            v-model="registerForm.phone"
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item prop="smsCode">
          <el-input
            prefix-icon="el-icon-message"
            v-model="registerForm.smsCode"
            placeholder="请输入验证码"
            style="width: 300px; margin-right: 20px"
          ></el-input>
          <el-button
            type="primary"
            :disabled="smsCodeDisabled"
            @click="sendSmsCode"
            >{{ smsCodeText }}</el-button
          >
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            prefix-icon="el-icon-lock"
            show-password
            v-model="registerForm.password"
            type="password"
            placeholder="至少6位密码，区分大小写"
          ></el-input>
        </el-form-item>
        <el-form-item prop="verifyPassword">
          <el-input
            prefix-icon="el-icon-lock"
            show-password
            v-model="registerForm.verifyPassword"
            type="password"
            placeholder="确认密码"
          ></el-input>
        </el-form-item>
        <!-- 身份证号 -->
        <el-form-item prop="identification">
          <el-input
            prefix-icon="el-icon-user-solid"
            v-model="registerForm.identification"
            placeholder="请输入身份证号"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 所在地 -->
        <el-form-item prop="">
          <span style="margin: 0 30px">所在地:</span>
          <template>
            <el-cascader
              placeholder="请选择所在地"
              v-model="areaId"
              :options="areaList"
              :props="props"
              @change="handleChange"
              clearable
              style="width: 340px"
            ></el-cascader>
          </template>
        </el-form-item>
        <!-- 单位 -->
        <el-form-item prop="">
          <span style="margin: 0 30px 0 45px">单位:</span>
          <template>
            <el-select
              v-model="unitId"
              clearable
              placeholder="请选择单位"
              style="width: 340px"
            >
              <el-option
                v-for="item in unitList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <!-- 职位 -->
        <el-form-item prop="">
          <span style="margin: 0 30px 0 45px">职位:</span>
          <template>
            <el-select
              v-model="positionId"
              clearable
              placeholder="请选择职位"
              style="width: 340px"
            >
              <el-option
                v-for="item in positionList"
                :key="item.id"
                :label="item.postionName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100%"
            class="registerBtn"
            type="primary"
            @click="submitForm('registerForm')"
            >注册</el-button
          >
        </el-form-item>
        <el-row>
          <el-col :span="17">
            <el-checkbox v-model="agree">
              <span style="color: #606266; font-size: 14px"
                >注册即表明同意</span
              >
            </el-checkbox>
            <span style="color: #409eff; font-size: 12px; cursor: pointer"
              >《隐私服务》</span
            >
            <span style="color: #409eff; font-size: 12px; cursor: pointer"
              >《用户服务协议》</span
            >
          </el-col>
          <el-col
            :span="7"
            style="
              text-decoration: none;
              text-align: right;
              color: #409eff;
              font-size: 14px;
              cursor: pointer;
              text-align: right;
              margin-top: 2px;
            "
            ><router-link to="/login">
              <span>使用已有账号登录 </span>
            </router-link>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<!-- JS代码 -->
<script>
import {
  userRegisterApi,
  sendSms,
  findProviceList,
  findCityList,
  findDistrictList,
  findStreetList,
} from "@/api/userLogin";
import {
  getAddPlatformPositionList,
  getAddCompanyList,
} from "@/api/trainingManage";
export default {
  data() {
    return {
      activeTab: "password",
      smsCodeText: "获取验证码",
      agree: false,
      registerForm: {
        phone: "",
        password: "",
        verifyPassword: "",
        identification: "",
        userName: "",
        smsCode: "",
      },
      props: {
        label: "name",
        value: "id",
        lazy: true,
        async lazyLoad(node, resolve) {
          const { level, value } = node;
          if (level == 1) {
            let res = await findCityList({ id: value });
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(res.data.msg);
          }
          if (level == 2) {
            let res = await findDistrictList({ id: value });
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(res.data.msg);
          }
          if (level == 3) {
            let res = await findStreetList({ id: value });
            // 当点击最后一级的时候 label 后面不会转圈圈 并把相关值赋值到选择器上
            res.data.msg.forEach((item) => (item.leaf = level >= 0));
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(res.data.msg);
          }
        },
      },
      smsCodeDisabled: false,
      // 校验规则
      registerRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        verifyPassword: [
          { required: true, message: "确认密码", trigger: "blur" },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        identification: [
          { required: true, message: "身份证号输入有误", trigger: "blur" },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern:
              /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            message: "身份证号输入有误",
            trigger: "blur",
          },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      areaList: [], // 地区列表
      unitList: [], // 单位
      positionList: [], // 职位
      areaId: "",
      provId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      unitId: "",
      positionId: "",
    };
  },
  created() {
    this.getFindProviceList();
    this.getAddPlatformPositionList();
  },
  methods: {
    getAreaList() {},
    handleChange(data) {
      console.log(data, "data", this.areaId);

      this.getAddCompanyList();
    },
    // 获取省级列表
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.areaList = res.data.msg;
      }
    },
    renderTime(date) {
      let dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    // 获取单位管理列表
    async getAddCompanyList() {
      let res = await getAddCompanyList({
        areaList: [
          {
            street: this.areaId[3],
            district: this.areaId[2],
            city: this.areaId[1],
            provice: this.areaId[0],
          },
        ],
        companyName: "",
        startNum: 1,
        pageSize: 1000,
      });
      if (res.data.result == "success") {
        this.unitList = res.data.list;
      }
    },
    // 获取岗位
    async getAddPlatformPositionList() {
      let res = await getAddPlatformPositionList();
      if (res.data.result == "success") {
        this.positionList = res.data.msg;
      }
    },
    // 获取职位列表
    // async getCompanyPositionList(id) {
    //   let res = await getCompanyPositionList({
    //     companyId: id, //公司id
    //     name: "", //名称
    //     status: "", //状态	true禁用	false禁用
    //     startNum: 1, //起始页码
    //     pageSize: 20, //每页条数
    //   });
    //   if (res.data.result == "success") {
    //     res.data.list.forEach((element) => {
    //       element.zhiwei.inTime = this.renderTime(element.zhiwei.inTime);
    //     });
    //     this.positionList = res.data.list;
    //   }
    //   console.log("getCompanyPositionList:", this.positionList);
    // },
    // 注册
    async submitForm(formName) {
      if (this.registerForm.password != this.registerForm.verifyPassword) {
        this.$message({
          message: "请确认两次密码输入是否一致",
          type: "warning",
        });
        return;
      }
      if (!this.agree) {
        this.$message({
          type: "warning",
          message: "请勾选隐私服务和服务用户协议",
        });
        return;
      }
      let param = {
        name: this.registerForm.userName,
        phone: this.registerForm.phone,
        captcha: this.registerForm.smsCode,
        password: this.registerForm.password,
        confirmPassword: this.registerForm.verifyPassword,
        idcard: this.registerForm.identification,
        province: this.areaId[0],
        city: this.areaId[1],
        region: this.areaId[2],
        street: this.areaId[3],
        educationCloudCompanyId: this.unitId,
        positionId: this.positionId,
      };
      console.log(param, "param", formName);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 表单验证通过，提交注册
          let res = await userRegisterApi(param);
          if (res.data.result == "success") {
            this.$confirm("注册成功是否前往登录", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            })
              .then(() => {
                this.$router.push({ path: "/login" });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
                window.location.reload();
              });
          }
          console.log(res, "res");
        } else {
          // 表单验证不通过
          console.log("validate failed");
          return false;
        }
      });
    },
    async sendSmsCode() {
      // 发送短信验证码
      console.log("send sms code", this.registerForm.phone);
      this.smsCodeDisabled = true;
      let count = 60;
      let res = await sendSms({
        phone: this.registerForm.phone,
      });
      console.log(res, "sendSmsCode");
      const timer = setInterval(() => {
        count--;
        if (count === 0) {
          clearInterval(timer);
          this.smsCodeText = "获取验证码";
          this.smsCodeDisabled = false;
        } else {
          this.smsCodeText = `${count}秒后重发`;
        }
      }, 1000);
    },
  },
};
</script>

<!-- CSS代码 -->
<style scoped>
.logo-and-name {
  width: 500px;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;
}
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.register-tabs {
  width: 400px;
}
.register-form {
  margin-top: 20px;
}
.registerBtn {
  padding: 12px 200px;
}
</style>
