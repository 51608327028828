<template>
  <div class="navMenu">
    <div :class="!isCollapse ? 'logo-unfold' : 'logo-retract'">
      <img
        src="../../public/logo.png"
        width="50px"
        height="50px"
        alt=""
        :style="
          !isCollapse
            ? 'vertical-align: middle; border-radius: 50%; margin-right: 10px;background-color:#fff'
            : 'vertical-align: middle; border-radius: 50%;width:30px;height:30px;background-color:#fff'
        "
      />
      <div v-if="!isCollapse">天津职健网管理平台</div>
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      :router="true"
      :unique-opened="true"
      :default-active="activeIndex"
      :collapse="isCollapse"
      :collapse-transition="false"
      @select="openMenu"
      background-color="#191a23"
      text-color="#ffffff"
      style="border: 0"
    >
      <template v-for="item in navMenuList">
        <!-- 一级菜单 -->
        <template v-if="item.menuLevel == 1">
          <!-- <template v-if="item.menuId == '0'">
            <el-menu-item
              :key="item.menuId"
              style="position: relative; color: #ffffff"
            >
              <i :class="item.menuIcon"></i>
              <a
                @click="navigateIndex"
                v-if="!isCollapse"
                style="color: #ffffff"
                >{{ item.menuName }}</a
              >
            </el-menu-item>
          </template> -->

          <el-menu-item :index="item.menuIndex" :key="item.menuId">
            <i :class="item.menuIcon"></i>

            <span slot="title">{{ item.menuName }}</span>
          </el-menu-item>
        </template>
        <!-- 二级菜单 -->
        <template v-if="item.menuLevel == 2">
          <el-submenu
            :index="item.menuIndex"
            :key="item.menuId"
            :popper-append-to-body="false"
          >
            <template slot="title">
              <i :class="item.menuIcon"></i>
              <span>{{ item.menuName }}</span>
            </template>
            <template v-for="menuChildren in item.childrens">
              <template v-if="menuChildren.menuId == '11'">
                <el-menu-item
                  :key="menuChildren.menuId"
                  style="position: relative; color: #ffffff"
                >
                  <i :class="menuChildren.menuIcon"></i>
                  <a
                    @click="navigatekec"
                    v-if="!isCollapse"
                    style="color: #ffffff; margin-left: -10px"
                    >{{ menuChildren.menuName }}</a
                  >
                </el-menu-item>
              </template>
              <template v-else-if="menuChildren.menuId == '12'">
                <el-menu-item
                  :key="menuChildren.menuId"
                  style="position: relative; color: #ffffff"
                >
                  <i :class="menuChildren.menuIcon"></i>
                  <a
                    @click="navigatewend"
                    v-if="!isCollapse"
                    style="color: #ffffff; margin-left: -10px"
                    >{{ menuChildren.menuName }}</a
                  >
                </el-menu-item>
              </template>
              <template v-else>
                <el-menu-item
                  :key="menuChildren.menuId"
                  :index="menuChildren.menuIndex"
                >
                  <template slot="title">
                    <span>{{ menuChildren.menuName }}</span>
                  </template>
                  <template
                    slot="title"
                    v-if="menuChildren.childrens.length != 0"
                  >
                    <el-submenu :index="menuChildren.menuIndex">
                      <template slot="title">
                        <i :class="item.menuIcon"></i>
                        <span>{{ menuChildren.menuName }}</span>
                      </template>
                      <template
                        v-for="menuChildrenSublevel in menuChildren.childrens"
                      >
                        <el-menu-item
                          :key="menuChildrenSublevel.menuId"
                          :index="menuChildrenSublevel.menuIndex"
                        >
                          <template slot="title">
                            <span>{{ menuChildrenSublevel.menuName }}</span>
                          </template>
                        </el-menu-item>
                      </template>
                    </el-submenu>
                  </template>
                </el-menu-item></template
              >
            </template>
          </el-submenu></template
        >

        <!-- 三级菜单 -->
        <template v-if="item.menuLevel == 3">
          <el-submenu
            :index="item.menuIndex"
            :key="item.menuId"
            :popper-append-to-body="false"
          >
            <template slot="title">
              <i :class="item.menuIcon"></i>
              <span>{{ item.menuName }}</span>
            </template>
            <template v-for="menuChildren in item.childrens">
              <template v-if="menuChildren.childrens.length != 0">
                <el-submenu
                  :index="menuChildren.menuIndex"
                  :key="menuChildren.menuId"
                >
                  <template slot="title">
                    <!-- <i :class="item.menuIcon"></i> -->
                    <span>{{ menuChildren.menuName }}</span>
                  </template>
                  <template
                    v-for="menuChildrenSublevel in menuChildren.childrens"
                  >
                    <template v-if="menuChildrenSublevel.menuId == 16">
                      <el-menu-item
                        :key="menuChildrenSublevel.menuId"
                        style="position: relative; color: #ffffff"
                      >
                        <i :class="menuChildrenSublevel.menuIcon"></i>
                        <a
                          @click="navigatetuw"
                          v-if="!isCollapse"
                          style="color: #ffffff; margin-left: 10px"
                          >{{ menuChildrenSublevel.menuName }}</a
                        >
                      </el-menu-item>
                    </template>
                    <template v-else-if="menuChildrenSublevel.menuId == 17">
                      <el-menu-item
                        :key="menuChildrenSublevel.menuId"
                        style="position: relative; color: #ffffff"
                      >
                        <i :class="menuChildrenSublevel.menuIcon"></i>
                        <a
                          @click="navigateship"
                          v-if="!isCollapse"
                          style="color: #ffffff; margin-left: 10px"
                          >{{ menuChildrenSublevel.menuName }}</a
                        >
                      </el-menu-item>
                    </template>
                    <template v-else>
                      <el-menu-item
                        :key="menuChildrenSublevel.menuId"
                        :index="menuChildrenSublevel.menuIndex"
                      >
                        <template slot="title">
                          <span>{{ menuChildrenSublevel.menuName }}</span>
                        </template>
                      </el-menu-item>
                    </template>
                  </template>
                </el-submenu>
              </template>
              <template v-if="menuChildren.childrens.length == 0">
                <!-- <el-menu-item
                  :key="menuChildren.menuId"
                  :index="menuChildren.menuIndex"
                >
                  <template slot="title">
                    <span>{{ menuChildren.menuName }}</span>
                  </template>
                </el-menu-item> -->
                <template>
                  <el-menu-item
                    :key="menuChildren.menuId"
                    style="position: relative; color: #ffffff"
                  >
                    <i :class="menuChildren.menuIcon"></i>
                    <a
                      @click="navigateshoy"
                      v-if="!isCollapse"
                      style="color: #ffffff; margin-left: -10px"
                      >{{ menuChildren.menuName }}</a
                    >
                  </el-menu-item>
                </template>
              </template>
            </template>
          </el-submenu></template
        >
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    navMenuList: {
      type: Array,
      require: true,
    },
    activeIndex: String,
  },
  data() {
    return {
      userInfo: {},
    };
  },
  created() {
    this.userInfo = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : "";
  },
  //导航菜单展开关闭切换
  computed: {
    isCollapse() {
      return this.navMenuToggle;
    },
  },
  methods: {
    openMenu(index, indexPah) {
      // console.log("hello", index, indexPah);
      this.$emit("changeMenuIndex", indexPah);
    },
    // 首页
    // navigateIndex() {
    //   console.log(this.userInfo);
    //   sessionStorage.setItem(
    //     "currentNavigate",
    //     JSON.parse(sessionStorage.getItem("navigateIndex")).menuIndex
    //   );
    //   window.location.replace(
    //     "http://admin.tjzj100.com/goIndex.action?userId=" +
    //       this.userInfo.id
    //   );
    // },
    // 课程
    navigatekec() {
      sessionStorage.setItem(
        "currentNavigate",
        JSON.parse(sessionStorage.getItem("navigateIndex")).menuIndex
      );
      sessionStorage.setItem("ExternalPath", true);
      window.location.replace(
        "http://admin.tjzj100.com/listLessonByNameAdmin.action?userId=" +
          this.userInfo.id
      );
    },
    // 问答
    navigatewend() {
      sessionStorage.setItem(
        "currentNavigate",
        JSON.parse(sessionStorage.getItem("navigateIndex")).menuIndex
      );
      sessionStorage.setItem("ExternalPath", true);
      window.location.replace(
        "http://admin.tjzj100.com/listLessonHourQuestionByhourId.action?userId=" +
          this.userInfo.id
      );
    },
    // 图文
    navigatetuw() {
      sessionStorage.setItem(
        "currentNavigate",
        JSON.parse(sessionStorage.getItem("navigateIndex")).menuIndex
      );
      sessionStorage.setItem("ExternalPath", true);
      window.location.replace(
        "http://admin.tjzj100.com/listAllExample.action?userId=" +
          this.userInfo.id
      );
    },
    // 视频
    navigateship() {
      sessionStorage.setItem(
        "currentNavigate",
        JSON.parse(sessionStorage.getItem("navigateIndex")).menuIndex
      );
      sessionStorage.setItem("ExternalPath", true);
      window.location.replace(
        "http://admin.tjzj100.com/listAllExample.action?userId=" +
          this.userInfo.id
      );
    },
    // 收益
    navigateshoy() {
      sessionStorage.setItem(
        "currentNavigate",
        JSON.parse(sessionStorage.getItem("navigateIndex")).menuIndex
      );
      sessionStorage.setItem("ExternalPath", true);
      window.location.replace(
        "http://admin.tjzj100.com/listRuleByAdmin.action?userId=" +
          this.userInfo.id
      );
    },
  },
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}
.logo-unfold {
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  display: flex;
}
.logo-retract {
  padding: 20px 5px;
  text-align: center;
}
.navMenu {
  background-color: #191a23;
  /* width: 15%; */
}
a {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-left: 50px;
}
</style>
