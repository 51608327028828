<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> {{ trainingObj.name ? trainingObj.name : "" }}</span>
      </div>
      <div class="text item" style="font-size: 14px">
        {{ trainingObj.content ? trainingObj.content : "" }}
      </div>
    </el-card>
    <div id="trainingSetupList">
      <el-row>
        <el-button
          :type="status == '' ? 'primary' : ''"
          round
          @click="cutStatus('')"
          >全部</el-button
        >
        <el-button
          :type="status == '1' ? 'primary' : ''"
          round
          @click="cutStatus('1')"
          >已发放</el-button
        >
        <el-button
          @click="cutStatus('0')"
          :type="status == '0' ? 'primary' : ''"
          round
          >未发放</el-button
        >
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <span>搜索内容：</span>
          <el-input
            style="width: 400px"
            v-model="searchInput"
            placeholder="输入框搜索功能"
            clearable
          ></el-input>
        </el-col>
        <!-- <el-col :span="8" :offset="1">
          <span>状态：</span>
          <el-select v-model="dropdownValue" placeholder="">
            <el-option label="全部" value=""></el-option>
            <el-option label="公开" value="1"></el-option>
            <el-option label="隐藏" value="2"></el-option>
          </el-select>
        </el-col> -->
        <el-col :span="4">
          <el-button type="primary" @click="search">查询</el-button>
          <!-- <el-button type="default" @click="reset">重置</el-button> -->
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <span>地区：</span>
          <span class="ml-20">省：</span>
          <el-select
            v-model="provId"
            placeholder="请选择省"
            clearable
            @change="getFindCityList(provId)"
            :disabled="
              $store.state.userPower == 3 ||
              $store.state.userPower == 4 ||
              $store.state.userPower == 5 ||
              $store.state.userPower == 6
            "
          >
            <el-option
              v-for="item in provList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">市：</span>
          <el-select
            v-model="cityId"
            placeholder="请选择市"
            @change="getFindDistrictList(cityId)"
            clearable
            :disabled="
              $store.state.userPower == 4 ||
              $store.state.userPower == 5 ||
              $store.state.userPower == 6
            "
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">区：</span>
          <el-select
            v-model="regionId"
            placeholder="请选择区"
            @change="getFindStreetList(regionId)"
            clearable
            :disabled="
              $store.state.userPower == 5 || $store.state.userPower == 6
            "
          >
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">街道：</span>
          <el-select
            v-model="streetId"
            placeholder="请选择街道"
            clearable
            :disabled="$store.state.userPower == 6"
            @change="getAddCompanyList"
          >
            <el-option
              v-for="item in streetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span class="ml-20">单位：</span>
          <el-select v-model="unitValue" placeholder="请选择单位" clearable>
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
          <el-button type="primary" v-if="status == '0'" @click="batchIssue"
            >批量颁发</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button type="primary" v-if="status == '0'" @click="daoBatchIssue"
            >导入颁发证书</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" v-if="status != '2'" @click="DerivedData"
            >导出数据</el-button
          >
        </el-col>
        <el-col :span="2" v-if="status != '2'">
          <el-upload
            class="upload-demo"
            action="none"
            :auto-upload="false"
            :on-change="commonYonghua"
            multiple
            :limit="1"
            :file-list="fileList"
            :on-exceed="handleExceed"
            :show-file-list="false"
          >
            <el-button type="primary">导入表格</el-button>
          </el-upload>
        </el-col>
        <el-col :span="4" style="line-height: 40px" v-if="status != '2'">
          <a @click="templateLink">用户导入模板下载</a>
        </el-col>
      </el-row>
      <!-- 导出数据弹窗 -->
      <el-dialog
        title="导出设置"
        :visible.sync="dialogVisiv"
        width="30%"
        :before-close="handleClose"
      >
        <template>
          <div style="margin: -20px 0 20px">请勾选需要加密的内容：</div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="1">身份证号</el-checkbox>
            <el-checkbox label="2">手机号</el-checkbox>
          </el-checkbox-group>
        </template>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiv = false">取 消</el-button>
          <el-button type="primary" @click="examineExportListAll"
            >确定导出</el-button
          >
        </span>
      </el-dialog>
      <el-table
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" fixed></el-table-column>
        <el-table-column prop="userId" label="uni-id" fixed></el-table-column>
        <!-- <el-table-column
          prop="studyProgress"
          label="排名"
          width="150"
          fixed
        ></el-table-column> -->
        <el-table-column prop="userName" label="用户姓名" width="150">
        </el-table-column>
        <el-table-column prop="idcard" label="身份证号" width="150">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="联系电话"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="所属单位"
          width="150"
        ></el-table-column>
        <el-table-column prop="inTime" label="单位所属地区" width="200">
          <template slot-scope="scope">
            {{ scope.row.provinceName ? scope.row.provinceName : "" }}
            {{ scope.row.cityName ? scope.row.cityName : "" }}
            {{ scope.row.regionName ? scope.row.regionName : "" }}
            {{ scope.row.streetName ? scope.row.streetName : "" }}
          </template>
        </el-table-column>
        <el-table-column prop="positionName" label="岗位" width="240">
        </el-table-column>
        <!-- <el-table-column label="申请状态" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.isIssue == 1 ? "已发放" : "未发放" }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="颁发状态" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.isIssue == 1 ? "已发放" : "未发放" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效学习时长" prop="studyHour" width="150">
          <template slot-scope="scope">
            {{ scope.row.studyHour ? scope.row.studyHour : 0 | timeCycle }}
          </template>
        </el-table-column>
        <el-table-column
          label="有效学习时长/总课时时长"
          prop="studyProgress"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.studyHourPercent }}%
          </template>
        </el-table-column>
        <el-table-column label="考试成绩(分)" prop="score" width="150">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="400">
          <template slot-scope="scope">
            <!-- <el-image
              style="width: 100px; height: 100px"
              ref="previewImg"
              :src="imageUrl"
              :preview-src-list="currentUserCertificate"
              v-show="false"
            >
            </el-image> -->

            <el-button
              v-if="scope.row.isIssue"
              type="primary"
              @click="checkCertificates(scope.row)"
              >查看证书</el-button
            >
            <el-button
              v-if="!scope.row.isIssue"
              type="primary"
              @click="issuingCertificates(scope.row)"
              >颁发证书</el-button
            >
            <el-button
              type="primary"
              v-if="scope.row.isIssue"
              @click="anewCertificates(scope.row)"
              >重新颁发</el-button
            >
            <el-button
              type="primary"
              @click="navigationCheckStudyBehaviorRecord(scope.row)"
              >查看学习行为记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      ></el-pagination>
      <el-image-viewer
        v-if="showImageViewer"
        :url-list="currentUserCertificate"
        :on-close="close"
      />
      <el-dialog title="选择证书模板" :visible.sync="dialogTableVisible">
        <el-dialog
          width="50%"
          title="是否为以下用户颁发证书"
          :visible.sync="innerVisible"
          append-to-body
        >
          <el-row style="display: flex; align-items: center">
            <el-col :span="4"> 证书模板：</el-col>
            <el-col :span="8">
              <el-image
                style="width: 100px; height: 100px"
                :src="selectionCertificate.templateUrl"
                :preview-src-list="selectionSrcList"
              >
              </el-image>
            </el-col>
          </el-row>

          <el-table
            :data="multipleSelection"
            border
            style="margin-top: 20px"
            max-height="500px"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column label="姓名" prop="userName" align="center">
            </el-table-column>
            <el-table-column label="所属单位" prop="companyName" align="center">
            </el-table-column>
            <el-table-column label="联系电话" prop="phone" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="danger" @click="deleteSelectionUser(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirmIssuingCertificates"
              >确定颁发</el-button
            >
          </span>
        </el-dialog>
        <template>
          <el-row>
            <el-col :span="8">
              <span>搜索内容：</span>
              <el-input
                style="width: 200px"
                v-model="CertificateSearchText"
                placeholder="输入框搜索功能"
                clearable
              ></el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="CertificateSearch"
                >查询</el-button
              >
            </el-col>
          </el-row>
          <el-table
            :data="CertificateTableData"
            border
            style="margin-top: 20px"
            max-height="500px"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              label="证书模板名称"
              prop="templateName"
              align="center"
            >
            </el-table-column>
            <el-table-column label="证书模板" align="center">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.templateUrl"
                  :preview-src-list="srcList"
                  :initial-index="scope.$index"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="primary" @click="useTemplates(scope.row)"
                  >使用</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-dialog>
      <el-dialog title="选择证书模板" :visible.sync="dialogTableVisible1">
        <el-dialog
          width="50%"
          title="是否为以下用户颁发证书"
          :visible.sync="innerVisible1"
          append-to-body
        >
          <el-row style="display: flex; align-items: center">
            <el-col :span="4"> 证书模板：</el-col>
            <el-col :span="8">
              <el-image
                style="width: 100px; height: 100px"
                :src="selectionCertificate.templateUrl"
                :preview-src-list="selectionSrcList"
              >
              </el-image>
            </el-col>
          </el-row>

          <el-table
            :data="multipleSelection"
            border
            style="margin-top: 20px"
            max-height="500px"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column label="姓名" prop="userName" align="center">
            </el-table-column>
            <el-table-column label="所属单位" prop="companyName" align="center">
            </el-table-column>
            <el-table-column label="联系电话" prop="phone" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="danger" @click="deleteSelectionUser(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirManewCertificates"
              >确定颁发</el-button
            >
          </span>
        </el-dialog>
        <template>
          <el-row>
            <el-col :span="8">
              <span>搜索内容：</span>
              <el-input
                style="width: 200px"
                v-model="CertificateSearchText"
                placeholder="输入框搜索功能"
                clearable
              ></el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="CertificateSearch"
                >查询</el-button
              >
            </el-col>
          </el-row>
          <el-table
            :data="CertificateTableData"
            border
            style="margin-top: 20px"
            max-height="500px"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              label="证书模板名称"
              prop="templateName"
              align="center"
            >
            </el-table-column>
            <el-table-column label="证书模板" align="center">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.templateUrl"
                  :preview-src-list="srcList"
                  :initial-index="scope.$index"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="primary" @click="useTemplates1(scope.row)"
                  >使用</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-dialog>
      <el-dialog title="选择证书模板" :visible.sync="dialogTableVisible2">
        <el-dialog
          width="50%"
          title="是否为以下用户颁发证书"
          :visible.sync="innerVisible2"
          append-to-body
        >
          <el-row style="display: flex; align-items: center">
            <el-col :span="4"> 证书模板：</el-col>
            <el-col :span="8">
              <el-image
                style="width: 100px; height: 100px"
                :src="selectionCertificate.templateUrl"
                :preview-src-list="selectionSrcList"
              >
              </el-image>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <el-upload
                class="upload-demo"
                action="none"
                :auto-upload="false"
                :on-change="commonYonghuaIssue"
                multiple
                :limit="1"
                :file-list="fileList"
                :on-exceed="handleExceed"
                :show-file-list="false"
              >
                <el-button type="primary">导入表格</el-button>
              </el-upload>
            </el-col>
            <el-col :span="6" style="line-height: 40px">
              <a @click="templateLinkIssue">用户导入模板下载</a>
            </el-col>
          </el-row>
        </el-dialog>
        <template>
          <el-row>
            <el-col :span="8">
              <span>搜索内容：</span>
              <el-input
                style="width: 200px"
                v-model="CertificateSearchText"
                placeholder="输入框搜索功能"
                clearable
              ></el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="CertificateSearch"
                >查询</el-button
              >
            </el-col>
          </el-row>
          <el-table
            :data="CertificateTableData"
            border
            style="margin-top: 20px"
            max-height="500px"
          >
            <el-table-column
              label="序号"
              type="index"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              label="证书模板名称"
              prop="templateName"
              align="center"
            >
            </el-table-column>
            <el-table-column label="证书模板" align="center">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.templateUrl"
                  :preview-src-list="srcList"
                  :initial-index="scope.$index"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="primary" @click="useTemplates2(scope.row)"
                  >使用</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  findCityList,
  findDistrictList,
  findProviceList,
  findStreetList,
} from "@/api/userLogin";
import { getCertificateTemplateList } from "@/api/certificateTemplate";
import {
  getPlatformTrainingIssueCertDataList,
  getAddCompanyList,
  saveCertificate,
  saveCertificatess,
  getCertByUserId,
  examineExportListAll,
  templateLink,
  templateLinkIssue,
  commonYonghua,
  commonYonghuaIssue,
} from "@/api/trainingManage";
export default {
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      status: "", // 状态
      imageUrl: "",
      showImageViewer: false,
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      unitList: [],
      provId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      unitValue: "",
      searchInput: "",
      dropdownValue: "",
      tableData: [], //学习情况列表
      trainingObj: "",
      dialogVisible: false,
      CertificateTableData: [], // 证书列表
      CertificateSearchText: "", // 证书关键字
      srcList: [], // 模板图片数组
      multipleSelection: [], // 选中需要颁发证书的数据
      multipleSelectionID: [],
      selectionCertificate: "", // 选中颁发的证书
      selectionSrcList: [], // 选中颁发证书的图片
      currentUserCertificate: [], // 当前用户证书
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      dialogTableVisible: false,
      innerVisible: false,
      dialogTableVisible1: false,
      innerVisible1: false,
      dialogTableVisible2: false,
      innerVisible2: false,
      detailObj: "",
      dialogVisiv: false, //导出数据
      checkList: [],
      selectedRows: "",
      fileList: [],
    };
  },
  filters: {
    // 过滤时间
    timeCycle(value) {
      const remainder = value % 60 < 10 ? "0" + (value % 60) : value % 60;
      const numStr = String((value / 60).toFixed(2));
      if (value > 60 && remainder == 0) {
        return (
          Math.floor(value / 60) +
          "分钟" +
          numStr.slice(numStr.indexOf(".") + 1) +
          "秒"
        );
      } else if (value > 60 && remainder != 0) {
        return Math.floor(value / 60) + "分钟" + remainder + "秒";
      } else {
        return value + "秒";
      }
    },
  },
  created() {
    this.getFindProviceList();
    switch (this.$store.state.userPower) {
      case 3:
        this.provId = this.$store.state.userInfo.province;
        break;
      case 4:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        break;
      case 5:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        this.regionId = this.$store.state.userInfo.region;
        break;
      case 6:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        this.regionId = this.$store.state.userInfo.region;
        this.streetId = this.$store.state.userInfo.street;
        break;
    }
    this.$nextTick(() => {
      this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
    });
  },
  mounted() {
    this.getFindCityList(this.provId);
    this.getFindDistrictList(this.cityId);
    this.getFindStreetList(this.regionId);
  },
  methods: {
    // 获取模版
    async templateLink() {
      let res = await templateLink();
      console.log(res, "1231321");
      let link = document.createElement("a");
      let body = document.querySelector("body");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      console.log(blob, "55");
      link.href = window.URL.createObjectURL(blob);
      link.download = `用户名单模版`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      this.dialogVisiv = false;
    },
    // 导入模版
    commonYonghua(file, fileList) {
      console.log(file, fileList);
      const isXlsx =
        file.raw.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.raw.type === "application/vnd.ms-excel";
      this.fileList = [];
      if (!isXlsx) {
        this.$message.error("上传文件只能是 Excel 表格!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("trainingId", this.$route.query.id);
      let result = commonYonghua(formData);
      result.then((res) => {
        console.log(res, "res");
        if (res.status == "200") {
          this.$message.success("导入成功");
          this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
        } else {
          this.$message.error("导入失败");
        }
      });
    },
    // 导入批量颁发模版
    async templateLinkIssue() {
      let res = await templateLinkIssue();
      console.log(res, "1231321");
      let link = document.createElement("a");
      let body = document.querySelector("body");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      console.log(blob, "55");
      link.href = window.URL.createObjectURL(blob);
      link.download = `批量颁发证书模版`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      this.dialogVisiv = false;
    },
    // 导入颁发证书模版
    commonYonghuaIssue(file, fileList) {
      console.log(file, fileList);
      const isXlsx =
        file.raw.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.raw.type === "application/vnd.ms-excel";
      this.fileList = [];
      if (!isXlsx) {
        this.$message.error("上传文件只能是 Excel 表格!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("trainingId", this.$route.query.id);
      formData.append("templateId", this.selectionCertificate.id);
      let result = commonYonghuaIssue(formData);
      result.then((res) => {
        console.log(res, "res");
        if (res.status == "200") {
          this.$message.success("颁发成功");
          this.dialogTableVisible2 = false;
          this.innerVisible2 = false;
        } else {
          this.$message.error("颁发失败");
        }
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 切换状态
    cutStatus(status) {
      this.status = status;
      this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
    },
    // 获取列表数据
    async getPlatformTrainingIssueCertDataList(id) {
      let param = {
        trainingId: id,
        name: this.searchInput,
        status: this.status,
        companyId: this.unitValue,
        provinceId: this.provId,
        cityId: this.cityId,
        regionId: this.regionId,
        streetId: this.streetId,
        pageSize: this.pageSize,
        startNum: this.currentPage,
      };
      // console.log(param, "param");
      let res = await getPlatformTrainingIssueCertDataList(param);
      if (res.data.result == "success") {
        this.tableData = res.data.issueCompanyList;
        this.trainingObj = res.data.tPlatformTraining;
        this.totalItems = res.data.size;
        // console.log(res, "getPlatformTrainingIssueCertDataList");
      }
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    // 获取单位管理列表
    async getAddCompanyList() {
      let res = await getAddCompanyList({
        companyName: this.unitValue,
        areaList: [
          {
            street: this.streetId,
            district: this.regionId,
            city: this.cityId,
            provice: this.provId,
          },
        ],
        startNum: 1,
        pageSize: 1000,
      });
      if (res.data.result == "success") {
        this.unitList = res.data.list;
      }
    },
    search() {
      // Implement search functionality
      this.currentPage = 1;
      this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
    },
    reset() {
      // Implement reset functionality
    },
    // 导出数据
    DerivedData() {
      this.dialogVisiv = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    async examineExportListAll() {
      let encryptIdCard = this.checkList.indexOf("1") != -1 ? true : false;
      let encryptPhone = this.checkList.indexOf("2") != -1 ? true : false;
      console.log(encryptPhone, encryptIdCard, this.checkList);
      let param = {
        trainingId: this.$route.query.id,
        name: this.searchInput,
        status: this.status,
        companyId: this.unitValue,
        provinceId: this.provId,
        cityId: this.cityId,
        regionId: this.regionId,
        streetId: this.streetId,
        encryptPhone: encryptPhone,
        encryptIdCard: encryptIdCard,
        ids: this.multipleSelectionID.join(","),
      };
      console.log(param, "param");
      let res = await examineExportListAll(param);
      console.log(res, "121");
      let link = document.createElement("a");
      let body = document.querySelector("body");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      console.log(blob, "55");
      link.href = window.URL.createObjectURL(blob);
      link.download = `普通用户名单`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      this.dialogVisiv = false;
    },
    // 查看证书
    async checkCertificates(row) {
      console.log(row);
      this.currentUserCertificate = [];
      let res = await getCertByUserId({
        userId: row.userId,
        trainingId: this.$route.query.id,
      });
      if (res.data.result == "success") {
        this.currentUserCertificate.push(
          res.data.educationCourseCertificate.certificatePhoto
        );
        this.imageUrl = res.data.educationCourseCertificate.certificatePhoto;
        this.showImageViewer = true;
        console.log(
          this.currentUserCertificate,
          "this.currentUserCertificate",
          this.$refs.previewImg
        );
        // setTimeout(() => {
        //   this.$refs.previewImg.showViewer = true; //图片预览第一种写法
        // }, 2000);

        // this.$refs.previewImg.clickHandler(); //图片预览第二种写法
      }
      console.log(res, "checkCertificates");
    },
    close() {
      this.currentUserCertificate = [];
      this.showImageViewer = false;
    },
    // 跳转查看学习行为记录
    navigationCheckStudyBehaviorRecord(row) {
      console.log(row, this.$route.query.id, "66");
      this.$router.push({
        path: "/checkStudyBehaviorRecord",
        query: { trainingId: this.$route.query.id },
      });
      sessionStorage.setItem(
        "studyRecord",
        JSON.stringify({
          user: row,
        })
      );
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
    },
    handleCurrentChange(page) {
      console.log(page, "55");
      this.currentPage = page;
      this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
    },
    // 颁发证书弹出获取证书模板数据
    async issuingCertificates(row) {
      console.log(row, "66");
      this.dialogTableVisible = true;
      if (row) {
        this.multipleSelection = [];
        this.multipleSelection.push(row);
      }
      console.log(this.multipleSelection, "issuingCertificates");
      let res = await getCertificateTemplateList({
        name: this.CertificateSearchText, //名称
        status: 1, //状态	true禁用	false启动
        startTime: "",
        endTime: "",
        startNum: 1, //起始页码
        pageSize: 10, //每页条数
      });
      if (res.data.result == "success") {
        res.data.educationCertificateTemplateList.forEach((element) => {
          this.srcList.push(element.templateUrl);
        });
        this.CertificateTableData = res.data.educationCertificateTemplateList;
        this.total = res.data.size;
      }
      console.log(this.CertificateTableData, "getCertificateTemplateList");
    },
    async anewCertificates(row) {
      console.log(row, "69");
      this.dialogTableVisible1 = true;
      if (row) {
        this.multipleSelection = [];
        this.multipleSelection.push(row);
      }
      console.log(this.multipleSelection, "issuingCertificates");
      let res = await getCertificateTemplateList({
        name: this.CertificateSearchText, //名称
        status: 1, //状态	true禁用	false启动
        startTime: "",
        endTime: "",
        startNum: 1, //起始页码
        pageSize: 10, //每页条数
      });
      if (res.data.result == "success") {
        res.data.educationCertificateTemplateList.forEach((element) => {
          this.srcList.push(element.templateUrl);
        });
        this.CertificateTableData = res.data.educationCertificateTemplateList;
        this.total = res.data.size;
      }
      console.log(this.CertificateTableData, "getCertificateTemplateList");
    },
    // 导入颁发证书
    async daoBatchIssue() {
      this.dialogTableVisible2 = true;
      // if (row) {
      //   this.multipleSelection = [];
      //   this.multipleSelection.push(row);
      // }
      // console.log(this.multipleSelection, "issuingCertificates");
      let res = await getCertificateTemplateList({
        name: this.CertificateSearchText, //名称
        status: 1, //状态	true禁用	false启动
        startTime: "",
        endTime: "",
        startNum: 1, //起始页码
        pageSize: 10, //每页条数
      });
      if (res.data.result == "success") {
        res.data.educationCertificateTemplateList.forEach((element) => {
          this.srcList.push(element.templateUrl);
        });
        this.CertificateTableData = res.data.educationCertificateTemplateList;
        this.total = res.data.size;
      }
    },
    // 批量选中需要颁发证书的用户
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.multipleSelectionID = val.map((item) => item.userId);

      // val.forEach((item) => this.multipleSelection.push(item.id));
      console.log(this.multipleSelection, "handleSelectionChange");
    },
    // 模板证书查询
    CertificateSearch() {
      this.issuingCertificates();
    },
    // 使用模板证书
    useTemplates(row) {
      console.log(row, "row");
      this.selectionCertificate = row;
      this.selectionSrcList[0] = row.templateUrl;
      this.innerVisible = true;
    },
    useTemplates1(row) {
      console.log(row, "row");
      this.selectionCertificate = row;
      this.selectionSrcList[0] = row.templateUrl;
      this.innerVisible1 = true;
    },
    useTemplates2(row) {
      console.log(row, "row");
      this.selectionCertificate = row;
      this.selectionSrcList[0] = row.templateUrl;
      this.innerVisible2 = true;
    },
    // 删除选中的颁发证书用户
    deleteSelectionUser(scope) {
      console.log(scope, "scope");
      this.multipleSelection.splice(scope.$index, 1);
    },
    // 确认颁发证书
    async confirmIssuingCertificates() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择颁发证书用户",
          type: "warning",
        });
        return;
      }
      let userIds = [];
      this.multipleSelection.forEach((item) => {
        userIds.push(item.userId);
      });
      console.log(this.multipleSelection, userIds);
      let res = await saveCertificate({
        userIds: userIds.join(","),
        traingId: this.$route.query.id,
        templateId: this.selectionCertificate.id,
      });
      if (res.data.result == "success") {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.innerVisible = false;
        this.dialogTableVisible = false;
        this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
        this.innerVisible = false;
        this.dialogTableVisible = false;
        this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
      }
      console.log(res);
    },
    // confirManewCertificates
    async confirManewCertificates() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择颁发证书用户",
          type: "warning",
        });
        return;
      }
      let userIds = [];
      this.multipleSelection.forEach((item) => {
        userIds.push(item.userId);
      });
      console.log(this.multipleSelection, userIds);
      let res = await saveCertificatess({
        userId: userIds.join(","),
        trainingId: this.$route.query.id,
        // templateId: this.selectionCertificate.id,
      });
      if (res.data.result == "success") {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.innerVisible1 = false;
        this.dialogTableVisible1 = false;
        this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
        this.innerVisible = false;
        this.dialogTableVisible = false;
        this.getPlatformTrainingIssueCertDataList(this.$route.query.id);
      }
      console.log(res);
    },
    // 批量颁发
    batchIssue() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择颁发证书用户",
          type: "warning",
        });
        return;
      }
      this.issuingCertificates();
    },

    // 跳转获取证书查询情况
    skipGetCertificateCondition() {
      this.$router.push({ path: "/getCertificateCondition" });
    },
  },
};
</script>

<style scoped>
/* Add custom styles here */
#trainingSetupList {
  padding: 20px;
}
.el-row {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.dialog-footer {
  text-align: center;
}
</style>
